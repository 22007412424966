export class CBterminal {
	private myDiv = null;
	private pre = null;
	private height;
	
	constructor(height = "400px") {
		//console.log("questo é il costruttore ");
		this.height = height;
	}
 
	
    open(term) {
		this.myDiv = term;
		term.style.height = this.height;
		//term.style.width = "400px";
		//term.style.overflowY = "auto";
		//term.style.border = "1px solid #ccc";
		const pre = document.createElement("pre");
		pre.style.textAlign = "left";
		pre.style.height = this.height;
		pre.style.overflowY = "auto";
		term.appendChild(pre);
		this.pre = pre; 
/*		
		// define some colors to be used inside <pre>
		const _red: CSSStyleSheet = new CSSStyleSheet();
		_red.replaceSync(".term_red {color:red;}");
		const _orange: CSSStyleSheet = new CSSStyleSheet();
		_orange.replaceSync(".term_orange {color:orange;}");
		const _green: CSSStyleSheet = new CSSStyleSheet();
		_green.replaceSync(".term_green {color:green;}");
		document.adoptedStyleSheets = [_red, _orange, _green];
*/		
	}
	close() {}
	   
	clear() {
	  if (this.pre != null) {
	    this.pre.textContent  = "";
	  }
	}
	
	write(data) {
	  //console.log("---" + data);
	  if (this.pre != null) {
	    this.pre.innerHTML  += data;
		this.pre.scrollTop = this.pre.scrollHeight;
	  }
	}
      
	writeln(data) {
	  //console.log("===" + data);
	  if (this.pre != null) {
		this.pre.innerHTML  += data + "\n";
		this.pre.scrollTop = this.pre.scrollHeight;
	  }	  
	}
	
	success(data) {
	  //console.log("===" + data);
	  if (this.pre != null) {
	    //this.pre.textContent  += data + "\n";
	    //this.pre.innerHTML  += '<span class="term_green">' +  data + "</span>\n";
		this.pre.innerHTML  += '<span style="color:green">' +  data + "</span>\n";
		this.pre.scrollTop = this.pre.scrollHeight;
	  }	  
	}
	  
	warning(data) {
	  //console.log("===" + data);
	  if (this.pre != null) {
	    //this.pre.textContent  += data + "\n";
	    //this.pre.innerHTML  += '<span class="term_green">' +  data + "</span>\n";
		this.pre.innerHTML  += '<span style="color:orange">' +  data + "</span>\n";
		this.pre.scrollTop = this.pre.scrollHeight;
	  }	  
	}

	
	error(data) {
	  //console.log(">>>" + data);
	  if (this.pre != null) {
		this.pre.innerHTML  += '\n<span style="color:red">' +  data + "</span>\n";
	    //this.pre.innerHTML  += '<span class="term_red">' +  data + "</span>\n";
		this.pre.scrollTop = this.pre.scrollHeight;
	  }	  
	}	
};  